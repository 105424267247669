import { DateTime } from "luxon"

function GetTodayStartEpoch()
{
    return new Date() - new Date().getTime() % 86400000
}

function GetTimeEpoch(hour, min, sec=0)
{
    return new Date(DateTime.local().setZone("utc+8").toFormat("yyyy-MM-dd") + "T" + ("0"+hour).slice(-2) + ":" + ("0"+min).slice(-2) + ":"+ ("0"+sec).slice(-2) + "+08:00").getTime()
}

function TimeString(format="yyyy-MM-ddThh:mm:ss", time = new Date())
{
    let datetimeString = format.replace(
        "yyyy", time.getFullYear()).replace(
            "MM", ("0"+(time.getMonth()+1)).slice(-2)).replace(
                "dd", ("0"+time.getDate()).slice(-2))
    datetimeString = datetimeString.replace(
        "hh", ("0"+time.getHours()).slice(-2)).replace(
            "mm", ("0"+time.getMinutes()).slice(-2)).replace(
                "ss", ("0"+time.getSeconds()).slice(-2))

    return datetimeString
}

export {GetTodayStartEpoch, GetTimeEpoch, TimeString}