import { useState, useMemo } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

import Toast from "react-bootstrap/Toast"

function AutoHideToast({delay=1000, title="test", message="test", type="info"})
{
    const [show, setShow] = useState(true)

    const Icon = useMemo(()=>{
        if (type === "info") return <FontAwesomeIcon style={{color: "#ffc107"}} icon={faBell}/>
        if (type === "error") return <FontAwesomeIcon style={{color: "#dc3545"}} icon={faCircleExclamation}/>
    }, [type])

    return useMemo(()=>{
        return (
            <Toast onClose={()=>{setShow(false)}} show={show} autohide delay={delay}>
                <Toast.Header style={{display: "flex", gap: 4}}>
                    {Icon}
                    <strong className="me-auto">{title}</strong>
                </Toast.Header>
                <Toast.Body>
                    {message}
                </Toast.Body>
            </Toast>
        )
    }, [show, title, message, Icon, delay])
}

export default AutoHideToast